html {
   font-size: 15px;
}
.dining {
  display: grid;
  grid-template-areas: ". neighborhoods ." ". locationContainer ." ". menuContainer ."
                        ". map ."
                        "footer footer footer";

  font-family: "Gotham A", "Gotham B", "san-serif";
  line-height: 200%;
  margin: 0 auto;
}
.map-container {
  position: relative;
  height:400px;
  width: 100%;
}
a {
  color: #57068c;
  cursor: pointer;
  text-decoration: none;
}
div {
  margin: 0;
  padding: 0;
  h1, h2, h3, h4, h5, h6, li, a {
      line-height: 125%;
      
  }
}
h3 {
  color: #000;
  font-family: "Gotham A","Gotham B", sans-serif;
  font-size: 2rem;
  font-weight: 700;
}
@media screen and (min-width:480px) {
  .dining{
    grid-template-columns: 1fr 480px 1fr;
  }
  .nyucolumncontrol {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .nyucolumncontrol .cc_50-50_c0, .nyucolumncontrol .cc_50-50_c1 {
    width: 100%;
  }
}
@media screen and (min-width:690px) {
  .dining {
    grid-template-columns: 1fr 690px 1fr;
  }
  .nyucolumncontrol .cc_50-50_c0, .nyucolumncontrol .cc_50-50_c1 {
    width: 50%;
  }
}
@media screen and (min-width:930px) {
  .dining {
    grid-template-columns: 1fr 930px 1fr;
  }
}