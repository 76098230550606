#locationContainer {
    grid-area: locationContainer;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
.parbase {
    clear: left;
}
.component {
    padding: 1rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
a.promo-button-text, a.promo-button-text:visited, button.promo-button-text, button.promo-button-text:visited {
    color: #57068c;
    border: 1px solid #57068c;
    padding: 0.733333333333333rem 1.33333333333333rem !important;
    display: inline-block;
    text-decoration: none;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-weight: 700;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 30rem;
    background: #fff;
}
p {
    color: #404040;
    font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
    font-size: 1rem;
    font-weight: normal;
    line-height: 200%;
}
  