#menucontainer {
    grid-area: menuContainer;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    h4 {
        margin-top: 10px;
        margin-left: 0px;
        padding-left: 0px;
        margin-bottom: 10px;
        font-size: 1.3rem;
    }
    ul {
        padding-top: .666666666666667rem;
        border: none;
        margin-right: 1rem;
        margin-left: 1rem;
        width: 100%;
        text-decoration: none;
        font-family: 'Gotham A','Gotham B', sans-serif;
        font-weight: 700;
        display: block;
        cursor: pointer;
        &::focus { 
            outline: #2dadd4 solid 2px;
        }
        &#treeContainer {
            display: block;
            margin-left: 2rem;
            // opacity: 0;
            // transform: scale(0);
            // transition: .6s ease opacity,.6s ease transform;
            [role="treeitem"]::before{
                content: none;
            }
            
            [role="treeitem"][aria-expanded="false"] > ul {
              display: none;
            }
            
            // [role="treeitem"][aria-expanded="true"] > ul {
            //   display: none;
            // }
            [role="treeitem"][aria-expanded="false"]::before {
                font-family: FontAwesome;
                            font-weight: 700;
                            display: -webkit-inline-flex;
                            display: -moz-inline-flex;
                            display: -ms-inline-flexbox;
                            display: inline-flex;
                            flex-direction: row;
                            padding: 0 .666666666666667rem 0 0;
                content: '\f067';
            }
            [role="treeitem"][aria-expanded="true"]::before {
                font-family: FontAwesome;
                            font-weight: 700;
                            display: -webkit-inline-flex;
                            display: -moz-inline-flex;
                            display: -ms-inline-flexbox;
                            display: inline-flex;
                            flex-direction: row;
                            padding: 0 .666666666666667rem 0 0;
              content: "\f068";
            }
        }
        li {
            padding-left: 6px;
            padding-bottom: 5px;
            &::marker {
                content: none;
            }
            
        }
    }
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

.form {
    padding:0;
    label, input {
        padding:1rem;
    }
}
.ui-input-text {
    padding-left:1rem;
    &::before {
        font-family: icomoon;
        font-size: 1.6rem;
        display: inline-block;
        width: 1.33333333333333rem;
        height: auto;
        margin-right: .666666666666667rem;
        content: '\e900';
        vertical-align: middle;
        color: #57068c;
        background-position: center;
    }
}
 input[type="text"] {
    font-family: 'Gotham A', 'Gotham B', sans-serif;
    font-size: 1rem;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: calc(100% - 2rem);
    padding: 1rem;
    border: .0666666666666667rem solid #d6d6d6;
    background: #f2f2f2;
}
.pika-single {
    font: 400 1rem 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    color: #000;
}
.pika-button {
    color: #57068c;
    font-size: 1rem;
    text-align: center;
    background: #ffffff;
}
.pika-next, .pika-prev {
    margin-top: .533333333333333rem;
    width: .533333333333333rem;
    height: .8rem;
}
.pika-next {
    background: url('https://www.nyu.edu/etc/designs/nyuseventy/clientlib/assets/img/input_sprite.png') no-repeat -7.06667rem -.66667rem;
}
.pika-prev {
    background: url('https://www.nyu.edu/etc/designs/nyuseventy/clientlib/assets/img/input_sprite.png') no-repeat -6rem -.66667rem;
}
.pika-table abbr {
    border: none;
    cursor: text;
}
iframe#nutritionInfo {
    position: absolute;
    border:none;
    display: block;
    width: 100%;
    height:960px;
}
#nutritionBox {
    position: relative;
    overflow:hidden;
    width: 100%;
    height: 450px;
}

.itemList [role="treeitem"] {
     border-bottom: .0666666666666667rem solid #d6d6d6;
}
li.mealPeriod {
    font-size: 1.2rem;
    color: #57068c;
}
span.category {
    font-size: 1.1rem;
    color: #57068c;
}

#date-heading {
                font-weight: bold;
                font-size: 1.2rem;
                color: #000000;
            }
#error-text {
                font-size: 1rem;
                color: #6d6d6d;
            }
.item {
    font-size: 1rem;
    color:  #404040;
    width: 100%;
    margin-left: 1.5rem;
    .name {
        float: left;
        padding-right: 5px;
    }
    img {
        max-width: 3rem;
        max-height: 1.25rem;
        padding-left: 5px;
    }
    .calories {
        float:right;
        text-align: left;
        padding-right: 10px;
        width:35%;
    }
}
#mealDate {
            width: initial;
}
#allergenKey img {
    padding-left: 10px;
    max-height: 1.2rem;
    position: relative;
    top:1px;
}
#dateForm .nyupromobutton, #mealDate, #submitDate {
    display: inline-block;
}
#menu-heading, .locationInfo {
    padding-left: 1rem;
}
#menucontainer h4, form#dateForm {
    padding-left: 0;
}
#menuContainter  h4 {
    margin-top: 10px;
    margin-left: 0px;
    padding-left: 0px;
    margin-bottom: 10px;
    font-size: 1.3rem;
}
.allergen {
    white-space: nowrap;
    padding-right: 10px;
    img {
        max-width: 3rem;
    }
}

#loading, h4#tree1 {
    margin-left: 1rem;
}
.hourListItem {
    display: block;
}
@media screen and (max-width: 62rem) {
    .item .calories {
        width: 20%;
    }
    .item {
        height: 3rem;
    }
}