.parbase {
    clear: left;
  }

.nyucolumncontrol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.cc_33-33-33_c0, .cc_33-33-33_c1, .cc_33-33-33_c2{
	width: 100%;
}
@media screen and (min-width: 46rem) {
    .cc_33-33-33_c0, .cc_33-33-33_c1, .cc_33-33-33_c2{
		width: 33.33333%;
	}
}