div {
    line-height: 200%;
  }
div {
    margin: 0;
    padding: 0;
}
.parbase {
    clear: left;
  }
.component {
    padding: 1rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.nyucolumncontrol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .cc_33-33-33_c0, .cc_33-33-33_c1, .cc_33-33-33_c2 {
         width:100%;
    }
}
@media screen and (min-width: 46rem){
    .nyucolumncontrol .cc_33-33-33_c0, .nyucolumncontrol .cc_33-33-33_c1, .nyucolumncontrol .cc_33-33-33_c2 {
          width: 33.33333%;
    }
}