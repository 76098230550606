#footer {
    grid-area: footer;
    margin-top:30px;
}
.footer-bottom-wrapper {
    background: #000;
    width: 100%;
    font-family: Gotham SSm 4r,Gotham SSm A,Gotham SSm B,Helvetica;
    font-size: 15px;
    box-sizing: border-box;
  }
.footer-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 1200px;
    position: relative;
    margin: 0 auto;
    padding: 1.33333333333333rem 1rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    .copyright {
        font-family: Mercury SSm A,Mercury SSm B,MercuryTextG2,serif;
        color: #f2f2f2;
        width: 22rem;
        font-size: 12px;
      }
  }
.footer-bottom-menu {
    width: 50rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 400px;
    .highlighted-item {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        font-size: 1.0666666666666667rem;
        padding-right: .5rem;
        a {
            line-height: 1.2rem;
            color: #fff;
            font-weight: 700;
            padding-right: .5rem;
            border-right: 2px solid #fff;
        }
    }
    ul{
        -webkit-box-sizing: border-box;
        margin: 0;
        padding-left: 0;
        box-sizing: border-box;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        li {
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            &::marker {
                content: none;
            }
            a {
                color: #f2f2f2;
                padding: 2px .25rem 0 .25rem;
                &:hover {
                    text-decoration: underline;
                }
                line-height: 200%;
            }
            
        }
    }
}