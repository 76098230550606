div {
    line-height: 200%;
    margin: 0;
    padding: 0;
}
.parbase {
    clear: left;
}
.component {
    padding: 1rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.location .promo-text-component {
    a, a:visited {
        color: #57068c;
        text-decoration: none;
    }
    a.promo {
          padding: 0.666666666666667rem 1rem 0 !important;
          display: block;
          min-width: 8.666666666666667rem;
          max-width: 30rem;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          background-color: rgba(0, 0, 0, 0);
          background-size: auto;
          background-size: 2.666666666666667rem auto;
          background-color: #fff;
        &:hover {
            div.OPEN {
                  background: #fff url("./img/promo_corner_green_hover.png") no-repeat right bottom;
                  background-size: auto;
                  background-size: 2.666666666666667rem auto;
            }
            div.CLOSED {
                  background: #fff url("./img/promo_corner_red_hover.png") no-repeat right bottom;
                  background-size: auto;
                  background-size: 2.666666666666667rem auto;
            }
        }
        h6 {
              color: #404040;
              font-family: "Gotham SSm A","Gotham SSm B", sans-serif;
              font-size: 1rem;
              font-weight: 700;
        }
        .promo-title {
              color: #57068c;
              padding: 0 0.33333333333333rem 0.33333333333333rem 0;
              margin: 0 !important;
              font-size: 1.2rem;
              line-height: 150%;
        }
        .promo-content p {
              line-height: 150%;
        }
        div.OPEN, div.CLOSED {
              display: inline-block;
              margin-bottom: 0.20rem;
              width: 100%;
              font-size: 0.9rem;
              margin: 0 0 0 -15px;
              padding: 9px 15px 9px 15px;
              background-repeat: no-repeat;
              background-position: right bottom;
              background-size: 2.666666666666667rem auto;
        }
        div.OPEN {
              background-image: url(./img/promo_corner_green.png);
              background-color: #ebf7f5;
              box-shadow: inset 0 -0.2rem 0 0 #086753;
              span.OPENText {
                  color: #086753;
              }
        }
        div.CLOSED {
              background-image: url(./img/promo_corner_red.png);
              background-color: #fef4f3;
              box-shadow: inset 0 -0.2rem 0 0 #b70709;
              span.CLOSEDText {
                  color: #b70709;
              }
        }
    }
    a.promo:not(.brand_color_1) {
              padding-bottom: 0.2666666666666667rem;
              -webkit-box-shadow: inset 0 -0.2rem 0 0 #57068c;
              box-shadow: inset 0 -0.2rem 0 0 #57068c;
              border: 1px solid #d6d6d6;
    }
}
div h1, div h2, div h3, div h4, div h5, div h6, div li, div a {
    line-height: 125%;
}
